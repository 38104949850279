<div class="one-blur-check-verification-modal">
  <div class="one-blur-check-verification-modal__header">
    <h4 class="one-blur-check-verification-modal__header__title">{{ 'modal.blur-check.pictures' | translate }}</h4>
    <p class="one-blur-check-verification-modal__header__counter">
      {{ 'modal.blur-check.counter' | translate: { index: index + 1, total: total } }}
    </p>
  </div>
  <div class="one-blur-check-verification-modal__image">
    <img class="one-blur-check-verification-modal__image__img" [src]="imageSrc" [alt]="'modal.blur-check.img-alt' | translate" />
  </div>
  <div class="one-blur-check-verification-modal__bottom-card">
    <div class="one-blur-check-verification-modal__bottom-card__blob">
      <qp-icon class="one-blur-check-verification-modal__bottom-card__blob__icon" [name]="iconNames.IC_INFORMATIVE_ALERT"></qp-icon>
      <span class="one-blur-check-verification-modal__bottom-card__blob__information">{{
        'modal.blur-check.' + blurType.toLowerCase() | translate
      }}</span>
    </div>
    <div class="one-blur-check-verification-modal__bottom-card__buttons">
      <qima-button
        (qimaButtonClick)="onKeepClick()"
        [qimaButtonSize]="qimaButtonSizes.LARGE"
        [qimaButtonStyle]="qimaButtonStyles.SECONDARY"
        [qimaButtonLabel]="'modal.blur-check.keep' | translate"
        [qimaButtonWidth]="qimaButtonWidths.FULL"
      >
      </qima-button>

      <qima-button
        (qimaButtonClick)="onRemoveClick()"
        [qimaButtonSize]="qimaButtonSizes.LARGE"
        [qimaButtonStyle]="qimaButtonStyles.PRIMARY_DESTRUCTIVE"
        [qimaButtonWidth]="qimaButtonWidths.FULL"
        [qimaButtonLabel]="'modal.blur-check.remove' | translate"
      >
      </qima-button>
    </div>
  </div>
</div>
