import { QpIconComponent } from '@library/components/qp-icon/qp-icon.component';
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { EQpIconButtonSize } from '@library/components/qp-icon-button/qp-icon-button.models';
import { TrackingService } from '@one/app/shared/services/third-party/analytics-manager/tracking/tracking.service';
import { anImageAnalysisCheckAction } from '@one/app/shared/services/third-party/analytics-manager/tracking/tracking.utils';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EQimaButtonSize, EQimaButtonStyle, EQimaButtonWidth, QimaButtonModule } from '@qima/ngx-qima';

@Component({
  selector: 'one-blur-check-verification-modal',
  templateUrl: './blur-check-verification-modal.component.html',
  styleUrls: ['./blur-check-verification-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [QpIconComponent, TranslateModule, QimaButtonModule],
})
export class BlurCheckVerificationModalComponent {
  public readonly qimaButtonSizes: typeof EQimaButtonSize = EQimaButtonSize;
  public readonly qimaButtonStyles: typeof EQimaButtonStyle = EQimaButtonStyle;
  public readonly qimaButtonWidths: typeof EQimaButtonWidth = EQimaButtonWidth;
  public readonly iconButtonSizes: typeof EQpIconButtonSize = EQpIconButtonSize;
  public readonly iconNames: typeof EQpIconName = EQpIconName;

  @Input() public total: number = 0;
  @Input() public index: number = 0;
  @Input() public imageSrc: string = '';
  @Input() public blurType: 'DEFOCUSED' | 'MOTION' = 'DEFOCUSED';

  @Output() public readonly keepImage: EventEmitter<boolean> = new EventEmitter<boolean>();

  public constructor(private readonly _trackingService: TrackingService) {}

  public onKeepClick(): void {
    this._trackingService.track(anImageAnalysisCheckAction('KEEP'));
    this.keepImage.emit(true);
  }

  public onRemoveClick(): void {
    this._trackingService.track(anImageAnalysisCheckAction('REMOVE'));
    this.keepImage.emit(false);
  }
}
